import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import defaultImage from '../images/icon_as.png'

function SEO({ url, lang, description, title, image }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          >
            <script
              SameSite="None; Secure"
              src="https://static.landbot.io/landbot-3/landbot-3.0.0.js"
            ></script>
            <title>{title}</title>
            <meta name="description" content={metaDescription} />
            <meta
              name="image"
              content={image ? `https:${image}` : defaultImage}
            />
            <meta
              name="google-site-verification"
              content="Ai8aeR4P9MHpVk2SCNgwBeofc-eXuhn_GYmbNZC73gQ"
            />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaDescription} />
            <meta
              property="og:image"
              content={image ? `https:${image}` : defaultImage}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@ASConsultoresMx" />
            <meta
              name="twitter:site"
              content="https://mobile.twitter.com/ASConsultoresMx"
            />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            <meta
              name="twitter:image"
              content={image ? `https:${image}` : defaultImage}
            />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `es`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

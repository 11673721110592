import React from 'react'
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faChevronRight,
  faChevronLeft,
  faAt,
  faPhone,
  faBuilding,
  faMapMarkerAlt,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import GlobalStyle from '../../styles/global'
import theme from '../../styles/theme'

import { ContainerPage } from './Layout.styled'
import Header from '../Header'
import Footer from '../Footer'
// import ModalSubscription from '../FooterContact/ModalSubscription'

library.add(
  fab,
  faChevronRight,
  faChevronLeft,
  faAt,
  faPhone,
  faBuilding,
  faMapMarkerAlt,
  faSearch,
  faTimes
)

const Layout = ({ children, ...props }) => {
  React.useEffect(() => {
    window.addEventListener('mouseover', initLandbot, { once: true })
    window.addEventListener('touchstart', initLandbot, { once: true })
    var myLandbot
    function initLandbot() {
      console.log('landbot', window.Landbot)
      if (!myLandbot) {
        var s = document.createElement('script')
        s.type = 'module'
        s.async = true
        s.addEventListener('load', function() {
          var myLandbot = new window.Landbot.Livechat({
            configUrl:
              'https://storage.googleapis.com/landbot.pro/v3/H-2730185-91H6SRZURQVP32JZ/index.json',
          })
          console.log('myLandbot', myLandbot)
        })
        s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.mjs'
        var x = document.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header location={props.location} algolia={props.algolia} />
        <ContainerPage>{children}</ContainerPage>
        <Footer />
        {/* <ModalSubscription /> */}
      </>
    </ThemeProvider>
  )
}

export default Layout
